@use "@angular/material" as mat;
@include mat.core();
@import "scss/themes/_colors.scss";

$company-primary: mat.define-palette($secondary-palette);
$company-accent: mat.define-palette($primary-palette);
// The warn palette is optional (defaults to red).
$company-warn: mat.define-palette($danger-palette);

$typography-config: mat.define-typography-config($font-family: 'Montserrat, sans-serif',
  );

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$company-theme: mat.define-light-theme((color: (primary: $company-primary,
        accent: $company-primary,
        warn: $company-warn,
      ),
      typography: $typography-config ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($company-theme);

/* You can add global styles to this file, and also import other style files */