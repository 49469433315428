$head-height: 56px;

.product-card-grid {
  padding-block: $gap-half;

  &__item {
    width: 100%;
    margin-bottom: $gap;
    height: calc(100% - #{$gap});

    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
    }
  }

  &.row {
    @include media-breakpoint-down(sm) {
      --bs-gutter-x: 8px;
    }
  }
}

.product-card {
  position: relative;
  border-bottom: 1px solid $secondary-50;
  background: $pure-white;
  padding: 8px;
  min-height: 100%;

  @include media-breakpoint-down(sm) {
    padding: 8px;
  }

  &--subscription {
    position: relative;
    height: 100%;

    .close-mark {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product-card__body {
      $mb: 30px;
      height: calc(100% - #{$mb*2});
      margin-block: $mb;

      section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &--check-address {
    position: relative;
    height: 100%;

    .close-mark {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product-card__body {
      $mb: 20px;
      height: calc(100% - $mb);
      margin-top: $mb;

      section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &--summary {
    border: 1px solid $form-disable-bg;
    padding: 16px;
    border-radius: 8px;

    hr {
      border-color: #9b9b9b;
    }

    .action-buttons {
      margin-top: 32px;
      display: flex;
      flex-direction: row-reverse;
      gap: 12px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;

        button {
          width: 100% !important;
        }
      }
    }
  }

  &--notify {
    border: 1px solid $form-disable-bg;
    padding: 16px;
    border-radius: 8px;
  }

  &__pre-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    position: absolute;
    right: 0;
    margin-top: 7px;
    margin-right: 7px;
    gap: 8px;
    z-index: 1;
    background-color: #ffffffa3;
    padding: 2px 5px;
    border-radius: 8px;

    span {
      cursor: pointer;
    }

    span:hover {
      opacity: 0.5;
    }
  }

  /*product-card-head*/
  &__head {
    height: $head-height;

    a {
      text-decoration: none;
    }
  }

  &__title {
    @include h5-bold;
    line-height: 1.3;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include media-breakpoint-down(sm) {
      @include h7-semibold;
      line-height: 1.3;
      height: 2em;
    }

    &.show-all {
      &:hover {
        height: 5ch !important;
        text-overflow: initial !important;
        white-space: normal !important; // white-space normal en lugar de text-wrap
      }
    }
  }

  &__teaser {
    @include h7-regular;
    margin-bottom: 0px;
  }

  .line-clamp-1 {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }

  .line-clamp-2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
    line-height: 1.1;
  }

  &__subtitle {
    @include h8-regular;
    margin-bottom: 0;
    margin-top: 4px;
    line-height: 1;
    height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*product-card-body*/
  .product-card__img-wrap {
    width: 100%;
    position: relative;
    margin-bottom: $gap-half;

    .info-badge {
      position: absolute;
      bottom: 8px;
      left: 8px;
    }

    .sale-badge {
      width: 25px;
      height: 25px;
      position: absolute;
      display: grid;
      place-content: center;
      background-color: $danger-500;
      color: $danger-contrast-500;
      font-size: $size-8;

      span {
        font-size: var(--font-size-5);
      }
    }

    .sale-badge::after {
      content: " ";
      width: 0px;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid $danger-500;
      position: absolute;
      bottom: -12px;
    }

    .disliked {
      filter: grayscale(1);
    }

    .liked {
      background: #0080003b;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  &__img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 3 / 1.7;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 0px;
  }

  &__meta--item {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    min-height: 40px;

    &.is-col-2 {
      //display: grid;
      //grid-template-columns: repeat(2, 50%);
      //@include media-breakpoint-down(lg) {
      //  grid-template-columns: 70% 30%;
      //}

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      // align-items: center;
    }

    .quantity {
      max-width: 75px;

      @include media-breakpoint-down(xl) {
        max-width: 66px;
      }

      @include media-breakpoint-down(lg) {
        max-width: 65px;

        .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
          padding-inline: 5px;
        }
      }

      @include media-breakpoint-down(sm) {
        max-width: 30px;
      }
    }

    .product-card__price {
      @include h5-bold;
      font-variant-numeric: tabular-nums;
      margin-bottom: 0;
      line-height: 1;

      @include media-breakpoint-down(sm) {
        font-size: $size-8;
      }
    }

    .product-card__price--line-through {
      @include media-breakpoint-down(sm) {
        font-size: calc($size-8 *.8);
      }

    }

    .product-card__info {
      text-overflow: ellipsis;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 33px;
      margin-bottom: 0;
      @include h8-bold;
      line-height: 1.2;
    }
  }

  &__action {
    position: absolute;
    bottom: 8px;
    right: 8px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 4px;
    }

    .btn {
      width: 46px !important;
      display: flex;
      height: 34px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      @include media-breakpoint-down(sm) {
        font-size: $size-8;
      }
    }

    .action-col-2 {
      display: flex;
      gap: $gap-half;

      .btn {
        white-space: nowrap;
      }
    }
  }

  &__foot {
    display: flex;

    &.is-col-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;

    }
  }

  .product-card__tags {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: $gap-half;
    height: 20px;

    li {
      padding: 1px 10px;
      border-radius: 40px;
      background-color: $primary-100;
      @include h8-bold;
    }

    // li>img {
    //   width: 24px;
    //   height: 24px;
    // }
  }

  .custom-checkbox {
    position: relative;
    cursor: pointer;

    .icon-outline {
      transition: all 0.2s ease-in-out;
    }

    .icon-solid {
      display: none;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .icon-solid {
      opacity: 0.5;
    }

    input[type="checkbox"]:checked~.icon-solid {
      display: block;
    }

    input[type="checkbox"]:checked~.icon-outline {
      display: none;
    }
  }

  //used for rounded-selector arrow position
  .mat-mdc-select-empty,
  .mat-form-field-hide-placeholder,
  .mdc-floating-label--float-above+.mat-mdc-select {
    .mat-mdc-select-arrow svg {
      margin-top: 10px;
    }
  }
}

.suggested {
  background-color: $tertiary-color;
}

.suggested::after {
  content: "Suggested bundle";
  @include h6-bold;
  background-color: $tertiary-color;
  border-color: black;
  position: absolute;
  padding: 2px 10px;
  top: -25px;
  left: 0px;
  height: 27px;
}

.keen-slider__slide {
  max-width: 250px;
  min-width: 250px;

  app-product-card {
    max-width: 250px;
    min-width: 250px;

    .product-card__tags {
      display: none !important;
    }
  }
}